<template>
  <div class="piano-main" :style="mainStyle">
    <div
      class="piano-octave-control piano-octave-control-lower"
      @click="changeOctave(-1)"
    >
      <span>←</span>
    </div>
    <div
      v-for="extendedNote of extendedNotes"
      class="piano-key"
      :key="currentOctave + extendedNote.fullName"
      :class="{
        'piano-key-black': isBlackKey(extendedNote.name),
        'piano-key-selected': extendedNote.fullName === selectedNote,
        'piano-key-pressed': extendedNote.fullName === pressedKey,
      }"
      @mousedown="handleClickOnNote($event, extendedNote.fullName)"
      @contextmenu="(e) => e.preventDefault()"
      @mouseenter="handleEnterOnNote($event, extendedNote.fullName)"
    >
      <span>{{ extendedNote.fullName }}</span>
    </div>
    <div
      class="piano-octave-control piano-octave-control-upper"
      @click="changeOctave(1)"
    >
      <span>→</span>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { clamp } from "../../utils/SHLMath";
import { PianoContext } from "../../utils/SHLTypes";

const OCTAVE_RANGE = {
  min: -4,
  max: 11,
};

const NOTE_NAME = [
  "C",
  "C#",
  "D",
  "D#",
  "E",
  "F",
  "F#",
  "G",
  "G#",
  "A",
  "A#",
  "B",
];

const PIANO_DIMENSIONS = {
  width: 1080,
  height: 160,
};

const PIANO_OFFSET = {
  x: 0,
  y: 40,
};

function calcFixedPosition({ x, y }) {
  x =
    clamp(
      x + PIANO_OFFSET.x,
      PIANO_DIMENSIONS.width / 2,
      window.innerWidth - PIANO_DIMENSIONS.width / 2
    ) -
    PIANO_DIMENSIONS.width / 2;
  y = clamp(
    y + PIANO_OFFSET.y,
    0,
    window.innerHeight - PIANO_DIMENSIONS.height
  );
  return { x, y };
}

function extractScientificNoteName(note: string) {
  if (note === "") return { name: "C", oct: 3 }; // empty note: return default
  const numStart = note.charAt(1) == "#" ? 2 : 1;
  return {
    name: note.slice(0, numStart),
    oct: +note.slice(numStart),
  };
}

export default Vue.extend({
  name: "Piano",
  props: {
    pianoContext: Object as PianoContext,
    pressedKey: String,
  },
  watch: {
    pianoContext(_, oldContext) {
      oldContext?.onCancel?.();
      this.selectedNote = this.pianoContext.currentNote;
      this.lookAtSelectedNote();
    },
  },
  mounted() {
    window.addEventListener("click", this.dismissListener);
    window.addEventListener("contextmenu", this.dismissListener);
    this.lookAtSelectedNote();
  },
  destroyed() {
    window.removeEventListener("click", this.dismissListener);
    window.removeEventListener("contextmenu", this.dismissListener);
  },
  data() {
    return {
      currentOctave: 2,
      dismissListener: (event) => {
        if (
          event != this.pianoContext.activatingMouseEvent &&
          event.target.closest(".piano-main") != this.$el
        ) {
          this.pianoContext.onCancel?.();
          this.$emit("dismiss");
        }
      },
      selectedNote: this.pianoContext.currentNote,
    };
  },
  computed: {
    extendedNotes() {
      const OCTAVE_LENGTH = 3;
      const ret = [];
      for (
        let oct = this.currentOctave;
        oct < this.currentOctave + OCTAVE_LENGTH;
        oct++
      ) {
        for (let noteIndex = 0; noteIndex < 12; noteIndex++) {
          ret.push({
            name: NOTE_NAME[noteIndex],
            oct: oct,
            fullName: NOTE_NAME[noteIndex] + oct,
          });
        }
      }
      return ret;
    },
    mainStyle() {
      const { x, y } = calcFixedPosition(
        this.pianoContext.activatingMouseEvent
      );
      return {
        width: PIANO_DIMENSIONS.width + "px",
        height: PIANO_DIMENSIONS.height + "px",
        left: x + "px",
        top: y + "px",
      };
    },
  },
  methods: {
    isBlackKey(name) {
      return typeof name == "string" && name.length == 2;
    },
    handleClickOnNote(event: MouseEvent, note) {
      event.preventDefault();
      if (event.buttons & 1) {
        this.pianoContext.onSelected(note);
        this.selectedNote = note;
      }
      this.pianoContext.instrument.triggerAttackRelease(note, "16n");
    },
    handleEnterOnNote(event: MouseEvent, note) {
      if (event.buttons != 0) {
        this.handleClickOnNote(event, note);
      }
    },
    changeOctave(delta) {
      this.setOctave(this.currentOctave + delta);
    },
    setOctave(newOct) {
      this.currentOctave = clamp(newOct, OCTAVE_RANGE.min, OCTAVE_RANGE.max);
    },
    lookAtSelectedNote() {
      const { oct } = extractScientificNoteName(this.selectedNote);
      this.setOctave(oct - 1);
    },
  },
});
</script>

<style scoped>
.piano-main {
  position: fixed;
  display: flex;
  background-color: #044;
  padding: 1px;
  border-radius: 4px;
  animation: FadeInDown 0.25s;
  transition: all 0.2s;
  box-shadow: 0 0 4px 0 #000;
  z-index: 999;
}

.piano-octave-control {
  transition: all 0.1s;
  width: 15px;
  display: flex;
  align-items: center;
  user-select: none;
  text-align: center;
}

.piano-octave-control:active {
  transform: translateY(2px);
}

.piano-octave-control-lower {
  border-radius: 4px 0 0 4px;
}

.piano-octave-control-upper {
  border-radius: 0 4px 4px 0;
}

.piano-octave-control:hover {
  background-color: #0ff;
}

.piano-octave-control span {
  flex-grow: 1;
}

.piano-key {
  display: flex;
  opacity: 0.6;
  transition: all 0.1s;
  margin: 0 1px;
  box-sizing: border-box;
  align-items: center;
  flex-grow: 1;
  padding: 0.25em 0;
  background-color: #fff;
  color: #000;
  writing-mode: vertical-lr;
  text-orientation: mixed;
}
.piano-key-black {
  background-color: #000;
  color: #fff;
}
.piano-key-selected {
  transform: translateY(2px);
  background-color: #0ff;
  color: #000;
  opacity: 1;
}
.piano-key-pressed {
  transform: translateY(2px);
  position: relative;
  opacity: 1;
}
.piano-key-pressed::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  width: 100%;
  height: 100%;
  border-bottom: #f80 4px solid;
  box-sizing: border-box;
}
.piano-key:active {
  transform: translateY(2px);
}
.piano-key:hover {
  opacity: 1;
}
.piano-key span {
  user-select: none;
  flex-grow: 1;
  text-align: right;
  display: inline-block;
  vertical-align: middle;
  animation: FadeInLeft 0.2s;
}
</style>
