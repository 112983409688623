<template>
  <div class="device-control-main">
    <ParamsControl
      :title="device.name"
      :options="options"
      @input="handleInput"
      :key="device.name"
      :path="device.name"
    >
      <component :is="customComponent" :device="device" />
    </ParamsControl>
    <div class="device-meta-control">
      <button
        v-if="deviceIndex !== undefined && deviceIndex !== 0"
        class="device-meta-button"
        @click="$emit('move', { from: deviceIndex, to: deviceIndex - 1 })"
      >
        <img :src="icons.playInvertIcon" alt="left icon" /></button
      ><button
        v-if="deviceIndex !== undefined && deviceIndex !== totalDeviceCount - 1"
        class="device-meta-button"
        @click="$emit('move', { from: deviceIndex, to: deviceIndex + 1 })"
      >
        <img :src="icons.playIcon" alt="right icon" /></button
      ><button
        v-if="deviceIndex !== undefined"
        class="device-meta-button"
        @click="$emit('delete', deviceIndex)"
      >
        X
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { ToneWithContext } from "tone/build/esm/core/context/ToneWithContext";
import ParamsControl from "./ParamsControl.vue";
import { CustomDeviceComponents } from "../configs/CustomDeviceComponents";
import PlayIcon from "../assets/icons/play.svg";
import PlayInvertIcon from "../assets/icons/playInvert.svg";

export default Vue.extend({
  name: "DeviceControl",
  components: {
    ParamsControl,
  },
  props: {
    device: ToneWithContext,
    deviceIndex: Number,
    totalDeviceCount: Number,
  },
  data() {
    return {
      options: this.device.get(),
      showDebug: false,
      icons: {
        playIcon: PlayIcon,
        playInvertIcon: PlayInvertIcon,
      },
    };
  },
  watch: {
    device() {
      this.options = this.device.get();
    },
  },
  computed: {
    customComponent() {
      return CustomDeviceComponents[this.device.name];
    },
  },
  methods: {
    handleInput(e) {
      this.device.set(e);
      this.options = this.device.get();
    },
  },
});
</script>

<style scoped>
.device-control-main {
  position: relative;
  display: flex;
  height: calc(100% - 8px);
  width: fit-content;
  box-sizing: border-box;
  margin: 4px;
  padding: 8px;
  border: #0ff 1px solid;
  border-radius: 8px;
  animation: FadeInLeft 0.25s;
}

.device-meta-control {
  transition: all 0.1s;
  opacity: 0;
  position: absolute;
  display: flex;
  right: 4px;
  top: 4px;
}

.device-control-main:hover .device-meta-control {
  opacity: 1;
}

.device-meta-button {
  outline: none;
  transition: all 0.1s;
  margin: 0 2px;
  opacity: 0.5;
  width: 24px;
  height: 24px;
  padding: 0;
  text-align: center;
  border-radius: 50%;
  background-color: #066;
  border: 2px #0ff ridge;
  color: #0ff;
}

.device-meta-button img {
  width: 100%;
  height: 100%;
}

.device-meta-button:hover {
  opacity: 1;
}

.device-meta-button:active {
  opacity: 1;
  transform: translateY(2px);
  background-color: #0aa;
}
</style>
