<template>
  <div class="knob-main" @mousedown="startDrag">
    <span>{{
      !isDragging
        ? truncateString(propName)
        : typeof mappedValue === "number"
        ? mappedValue.toFixed(mappedValue >= 100 ? 0 : 1)
        : mappedValue
    }}</span>
    <svg>
      <path
        :d="backgroundPath"
        stroke="#00ffff22"
        fill="transparent"
        stroke-width="4px"
        stroke-linecap="round"
      />
      <path
        :d="fillPath"
        stroke="#0ff"
        fill="transparent"
        stroke-width="4px"
        stroke-linecap="round"
      />
      <circle
        :cx="handleX"
        :cy="handleY"
        r="4"
        stroke="#0ff"
        stroke-width="2px"
        fill="#000"
      />
    </svg>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import {
  deg2rad,
  lerp,
  logLerp,
  clamp,
  normalize,
  logNormalize,
  map,
} from "../../utils/SHLMath";

export default Vue.extend({
  name: "Knob",
  props: {
    propName: {
      type: String,
      default: "Knob",
    },
    value: [Number, String],
    paramAttributes: Object,
  },
  data() {
    return {
      radius: 26,
      origin: {
        x: 32,
        y: 32,
      },
      startAngle: 120,
      endAngle: 420,
      registeredOnDrag: this.onDrag.bind(this),
      registeredEndDrag: this.endDrag.bind(this),
      isDragging: false,
      amount: this.isLog
        ? logNormalize(
            this.value,
            this.paramAttributes.min,
            this.paramAttributes.max
          )
        : normalize(
            this.value,
            this.paramAttributes.min,
            this.paramAttributes.max
          ),
    };
  },
  // TODO: is this needed?
  mounted() {
    this.amount = this.normalizedAmount;
  },
  computed: {
    isLog() {
      return this.paramAttributes.type === "log";
    },
    startPoint() {
      return {
        x: this.origin.x + Math.cos(deg2rad(this.startAngle)) * this.radius,
        y: this.origin.y + Math.sin(deg2rad(this.startAngle)) * this.radius,
      };
    },
    endPoint() {
      return {
        x: this.origin.x + Math.cos(deg2rad(this.endAngle)) * this.radius,
        y: this.origin.y + Math.sin(deg2rad(this.endAngle)) * this.radius,
      };
    },
    currAngle() {
      return lerp(this.amount, this.startAngle, this.endAngle);
    },
    currPoint() {
      const currRad = deg2rad(this.currAngle);
      return {
        x: this.origin.x + Math.cos(currRad) * this.radius,
        y: this.origin.y + Math.sin(currRad) * this.radius,
      };
    },
    fillPath() {
      const useLargeArc = this.currAngle - this.startAngle >= 180 ? 1 : 0;
      return `M${this.startPoint.x}, ${this.startPoint.y} A ${this.radius} ${this.radius}, 0, ${useLargeArc}, 1, ${this.currPoint.x} ${this.currPoint.y}`;
    },
    handleX() {
      return this.currPoint.x;
    },
    handleY() {
      return this.currPoint.y;
    },
    backgroundPath() {
      const useLargeArc = this.endAngle - this.startAngle >= 180 ? 1 : 0;
      return `M${this.startPoint.x}, ${this.startPoint.y} A ${this.radius} ${this.radius}, 0, ${useLargeArc}, 1, ${this.endPoint.x} ${this.endPoint.y}`;
    },
    mappedValue() {
      switch (this.paramAttributes.type) {
        case "log":
          return logLerp(
            this.amount,
            this.paramAttributes.min,
            this.paramAttributes.max
          );
        case "enum": {
          let index = Math.floor(
            map(this.amount, 0, 1, 0, this.paramAttributes.values.length)
          );
          if (index == this.paramAttributes.values.length) index--;
          return this.paramAttributes.values[index];
        }
        case "int":
          return Math.round(
            lerp(
              this.amount,
              this.paramAttributes.min,
              this.paramAttributes.max
            )
          );
        default:
          return lerp(
            this.amount,
            this.paramAttributes.min,
            this.paramAttributes.max
          );
      }
    },
    normalizedAmount() {
      switch (this.paramAttributes.type) {
        case "log":
          return logNormalize(
            this.value,
            this.paramAttributes.min,
            this.paramAttributes.max
          );
        case "enum": {
          let index = this.paramAttributes.values.findIndex(
            (val) => val === this.value
          );
          return map(index + 0.5, 0, this.paramAttributes.values.length, 0, 1);
        }
        default:
          return normalize(
            this.value,
            this.paramAttributes.min,
            this.paramAttributes.max
          );
      }
    },
  },
  methods: {
    startDrag(e: MouseEvent) {
      e.preventDefault();
      this.isDragging = true;
      window.addEventListener("mousemove", this.registeredOnDrag);
      window.addEventListener("mouseup", this.registeredEndDrag);
      window.addEventListener("blur", this.registeredEndDrag);
    },
    onDrag(e: MouseEvent) {
      const oldValue = this.mappedValue;
      this.amount -= e.movementY * (e.shiftKey ? 0.0005 : 0.01);
      this.amount = clamp(this.amount, 0, 1);
      if (this.mappedValue != oldValue)
        this.$emit("input", { [this.propName]: this.mappedValue });
    },
    endDrag() {
      this.isDragging = false;
      window.removeEventListener("mousemove", this.registeredOnDrag);
      window.removeEventListener("mouseup", this.registeredEndDrag);
      window.removeEventListener("blur", this.registeredEndDrag);
    },
    truncateString(str) {
      if (str.length <= 5) return str;
      const lastChar = str.substr(-1, 1);
      if (lastChar >= "0" && lastChar <= "9") {
        return str.slice(0, 4) + lastChar;
      }
      return str.slice(0, 5);
    },
  },
});
</script>

<style scoped>
.knob-main {
  position: relative;
  text-align: center;
  width: 64px;
  height: 64px;
}
svg {
  width: 100%;
  height: 100%;
}
span {
  position: absolute;
  width: 100%;
  color: #0ff;
  top: calc(50% - 0.75em);
  user-select: none;
}
</style>
