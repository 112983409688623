<template>
  <div
    class="track-main"
    :class="{ 'track-active': selected }"
    @click="onTrackFocus"
    @contextmenu="showContextMenu"
  >
    <div class="track-left">
      <StyledButton
        class="track-delete-button"
        @click="$emit('track-delete', track.uid)"
        >X</StyledButton
      >
      <input
        v-if="isEditingName"
        class="track-name"
        v-model="track.name"
        ref="nameInput"
        @keydown="(e) => e.stopPropagation()"
        @blur="isEditingName = false"
        @change="handleNameChange"
      />
      <div v-else class="track-name" @dblclick="startEditName">
        {{ track.name }}
      </div>
      <ChannelControl
        class="channel-control"
        :channel="channelParams"
        @update:channel="track.channel.set($event)"
      />
      <MeterVisualizer :meter="track.meter" />
    </div>
    <div class="track-right">
      <SequencerCell
        v-for="(_, index) of track.sequenceData"
        :key="index"
        v-model="track.sequenceData[index]"
        class="track-seq-cell"
        :class="{ 'timeslot-input-active': track.currentStep === index }"
        :mono="track.instrument.name === 'SHLMonoSampler'"
        :instrument="track.instrument"
        @update="updateSequence"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { TrackOptions, SHLTrack } from "../SHLTone/SHLTrack";
import ChannelControl from "./ChannelControl.vue";
import SequencerCell from "./SequencerCell.vue";
import MeterVisualizer from "./MeterVisualizer.vue";
import StyledButton from "./widgets/StyledButton.vue";

export default Vue.extend({
  name: "Track",
  components: { StyledButton, MeterVisualizer, SequencerCell, ChannelControl },
  inject: [
    "callMenu",
    "getClipboard",
    "setClipboard",
    "addTrackAsNew",
    "requestNewTrackUid",
  ],
  props: {
    trackOptions: TrackOptions,
    selected: Boolean,
  },
  data() {
    return {
      track: new SHLTrack(),
      isEditingName: false,
      lastName: "",
      contextActions: {
        "Copy Track": {
          predicate: () => true,
          action: this.copyTrack.bind(this),
        },
        "Copy Sequence": {
          predicate: () => true,
          action: this.copySequence.bind(this),
        },
        "Paste Track (Overwrite)": {
          predicate: (clipboard) =>
            clipboard != null && clipboard.type === "track",
          action: this.pasteTrack.bind(this),
        },
        "Paste Track As New": {
          predicate: (clipboard) =>
            clipboard != null && clipboard.type === "track",
          action: this.pasteTrackAsNew.bind(this),
        },
        "Paste Sequence": {
          predicate: (clipboard) =>
            clipboard != null && clipboard.type === "sequence",
          action: this.pasteSequence.bind(this),
        },
      },
    };
  },
  methods: {
    updateSequence() {
      this.track.reloadSequence();
    },
    onTrackFocus() {
      this.$emit("track-focus", {
        track: this.track,
        uid: this.trackOptions.uid,
      });
    },
    startEditName() {
      this.lastName = this.track.name;
      this.isEditingName = true;
      this.$nextTick().then(() => this.$refs.nameInput.focus());
    },
    handleNameChange() {
      if (this.track.name === "") {
        this.track.name = this.lastName;
      }
      this.isEditingName = false;
    },
    showContextMenu(event: MouseEvent) {
      this.onTrackFocus();
      if (event.defaultPrevented) return;
      event.preventDefault();
      this.callMenu(
        Object.keys(this.contextActions).filter((key) =>
          this.contextActions[key].predicate(this.getClipboard())
        ),
        event,
        (item) => {
          this.contextActions[item].action();
        }
      );
    },
    copySequence() {
      this.setClipboard({
        type: "sequence",
        data: [...this.track.sequenceData],
      });
    },
    pasteSequence() {
      this.track.sequenceData = this.getClipboard().data;
      this.updateSequence();
    },
    copyTrack() {
      this.setClipboard({
        type: "track",
        data: this.track.toOptions(),
      });
    },
    pasteTrack() {
      this.track.fromOptions(this.getClipboard().data);
      this.track.uid = this.requestNewTrackUid();
    },
    pasteTrackAsNew() {
      this.addTrackAsNew(this.getClipboard().data);
    },
  },
  computed: {
    channelParams() {
      return {
        volume: this.track.channel.volume.value,
        pan: this.track.channel.pan.value,
        solo: this.track.channel.solo,
        mute: this.track.channel.mute,
      };
    },
  },
  created() {
    this.track.fromOptions(this.trackOptions);
    this.$emit("track-created", { track: this.track, uid: this.track.uid });
  },
  destroyed() {
    this.track?.dispose();
  },
});
</script>

<style scoped>
.track-main {
  height: 60px;
  display: flex;
  margin: 0.5em auto;
  padding: 4px;
  border: #0ff 2px solid;
  border-radius: 8px;
  box-sizing: content-box;
  transition: background-color 0.5s;
}
@media (max-width: 1366px) {
  .track-main {
    flex-direction: column;
    height: auto;
    align-items: stretch;
  }
}
.track-active {
  background-color: #044;
}
.meter-vis-main {
  align-self: stretch;
  height: 60px;
}
.track-name {
  appearance: none;
  flex-grow: 1;
  width: 80px;
  font-size: 2em;
  padding: 0 0 0 1em;
  overflow: hidden;
  background: rgba(0, 0, 0, 0);
  border: none;
  color: #0ff;
  font-family: inherit;
}
.track-name:active,
.track-name:focus {
  appearance: none;
  margin: 0;
  outline: none;
  border: none;
}
.timeslot-input-active {
  opacity: 1;
}

.track-left {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.track-right {
  display: flex;
  flex-grow: 2;
}

.track-seq-cell {
  flex-grow: 1;
  width: 1em;
}

.track-delete-button,
.track-delete-button:active {
  opacity: 0;
  position: absolute;
  padding: 4px;
  width: 1em;
  height: 1em;
  border-color: #f00;
  color: #f00;
}

.track-main:hover .track-delete-button {
  opacity: 0.25;
}

.track-main:hover .track-delete-button:hover {
  opacity: 1;
  background-color: #400;
}
</style>
