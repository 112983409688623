<template>
  <div class="daw-main" @click="initAudio">
    <div class="global-control">
      <div class="transport-control">
        <div />
        <StyledButton @click="toggleTransport" class="play-button">
          <img
            :src="isTransportPlaying ? icons.stop : icons.play"
            alt="play icon"
          />
        </StyledButton>
        <StyledButton @click="toggleRecord" class="play-button"
          ><img
            :src="isRecording ? icons.recordInvert : icons.record"
            alt="record icon"
          />
        </StyledButton>
        <div class="bpm-setting">
          <input v-model="bpm" @change="setBpm" class="bpm-input" />BPM
        </div>
        <div class="swing-setting">
          <div>swing amount</div>
          <input
            type="range"
            min="0"
            max="1"
            step="0.05"
            v-model="transport.swing"
          />
        </div>
      </div>
      <div class="project-control">
        <StyledButton @click="importProject">import</StyledButton>
        <StyledButton @click="exportProject">export</StyledButton>
        <StyledButton @click="clearProject">clear</StyledButton>
      </div>
    </div>
    <div class="track-container">
      <Track
        v-for="trackRecord of projectData"
        :key="trackRecord.initOptions.uid"
        :track-options="trackRecord.initOptions"
        :selected="
          selectedTrackInfo &&
          selectedTrackInfo.uid === trackRecord.initOptions.uid
        "
        @evoke-piano="evokePiano"
        @track-focus="onTrackFocus"
        @track-created="onTrackCreated"
        @track-delete="onTrackDelete"
      />
      <StyledButton class="add-new-track-button" @click="openAddNewTrackMenu"
        >Add new track</StyledButton
      >
    </div>
    <div id="track-panel"></div>
    <TrackDetailPanel
      v-if="selectedTrackInfo"
      :track="selectedTrackInfo.track"
      @key-pressed="pressedKey = $event"
    />
    <Piano
      v-if="pianoContext"
      :piano-context="pianoContext"
      :pressed-key="pressedKey"
      @dismiss="dismissPiano"
    />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import * as Tone from "tone";
import Track from "./Track.vue";
import { InstrumentList } from "../configs/DeviceList";
import LeadPreset from "../SHLTone/presets/SHLSynthLead1";
import { LeadSequence, KickSequence } from "../SHLTone/sequences/TakeOnMe";
import TrackDetailPanel from "./TrackDetailPanel.vue";
import Piano from "./widgets/Piano.vue";
import { PianoContext } from "../utils/SHLTypes";
import StyledButton from "./widgets/StyledButton.vue";
import PlayIcon from "../assets/icons/play.svg";
import StopIcon from "../assets/icons/stop.svg";
import RecordIcon from "../assets/icons/record.svg";
import RecordInvertIcon from "../assets/icons/recordInvert.svg";
import { clamp } from "../utils/SHLMath";
import { SHLTrack, TrackOptions } from "../SHLTone/SHLTrack";

type TrackRecord = {
  instance?: SHLTrack;
  initOptions: TrackOptions;
};

export default Vue.extend({
  name: "WebDAW",
  components: {
    StyledButton,
    TrackDetailPanel,
    Track,
    Piano,
  },
  data() {
    return {
      isTransportPlaying: false,
      recorder: new Tone.Recorder(),
      isRecording: false,
      transport: Tone.Transport,
      bpm: 86,
      icons: {
        play: PlayIcon,
        stop: StopIcon,
        record: RecordIcon,
        recordInvert: RecordInvertIcon,
      },
      selectedTrackInfo: null,
      projectData: [
        {
          initOptions: {
            name: "Lead",
            uid: -1,
            instrument: {
              name: "SHLSynth",
              data: Object.assign({ volume: -12 }, LeadPreset),
            },
            effects: [],
            sequence: LeadSequence,
          },
        },
        {
          initOptions: {
            name: "Kick",
            uid: -2,
            instrument: {
              name: "SHLMonoSampler",
            },
            effects: [],
            sequence: KickSequence,
          },
        },
      ] as TrackRecord[],
      uidCounter: 0,
      pianoContext: null as PianoContext,
      pressedKey: "",
      clipboard: null,
    };
  },
  inject: ["callMenu"],
  provide() {
    return {
      getClipboard: this.getClipboard,
      setClipboard: this.setClipboard,
      addTrackAsNew: function (trackOptions) {
        this.projectData.push({
          initOptions: {
            ...trackOptions,
            uid: this.uidCounter++,
          },
        });
      }.bind(this),
      requestNewTrackUid: function () {
        return this.uidCounter++;
      }.bind(this),
    };
  },
  methods: {
    initAudio() {
      Tone.start();
      this.transport.swingSubdivision = "16n";
    },
    toggleTransport() {
      this.isTransportPlaying = !this.isTransportPlaying;
      if (this.isTransportPlaying) {
        Tone.Transport.start();
      } else {
        Tone.Transport.pause();
      }
    },
    toggleRecord() {
      if (!this.isRecording) {
        this.recorder.start();
        this.transport.position = 0;
      } else {
        this.recorder.stop().then((blob) => {
          const url = URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.download = "recording.webm";
          anchor.href = url;
          anchor.click();
        });
      }
      this.isRecording = !this.isRecording;
      if (this.isTransportPlaying != this.isRecording) this.toggleTransport();
    },
    setBpm() {
      this.bpm = clamp(+this.bpm, 1, 300);
      if (isNaN(this.bpm)) this.bpm = 120;
      Tone.Transport.set({
        bpm: this.bpm,
      });
    },
    onTrackFocus(event) {
      this.selectedTrackInfo = event;
    },
    onTrackDelete(targetUid) {
      if (this.selectedTrackInfo && this.selectedTrackInfo.uid == targetUid)
        this.selectedTrackInfo = null;
      console.log(targetUid);
      this.projectData = this.projectData.filter(
        (trackRecord) => trackRecord.initOptions.uid !== targetUid
      );
    },
    openAddNewTrackMenu(event: MouseEvent) {
      this.callMenu(Object.keys(InstrumentList), event.target, (item) =>
        this.onAddNewTrackMenuSelected(item)
      );
    },
    onAddNewTrackMenuSelected(item) {
      this.projectData.push({
        initOptions: {
          name: item,
          uid: this.uidCounter++,
          instrument: {
            name: item,
          },
        },
      });
    },
    onTrackCreated({ track, uid }) {
      this.projectData.find(
        (trackRecord) => trackRecord.initOptions.uid === uid
      ).instance = track;
      this.selectedTrackInfo = {
        track: track,
        uid: uid,
      };
    },
    evokePiano(pianoContext: PianoContext) {
      this.pianoContext = pianoContext;
      this.pressedKey = "";
    },
    dismissPiano() {
      this.pianoContext = null;
    },
    clearProject() {
      this.projectData = [];
      this.uidCounter = 0;
      this.selectedTrackInfo = null;
      this.bpm = 86;
      this.setBpm();
      this.transport.swing = 0;
      this.transport.position = 0;
    },
    exportProject() {
      const ret = {};
      ret.bpm = this.bpm;
      ret.swing = this.transport.swing;
      ret.tracks = [];
      for (let trackRecord of this.projectData) {
        ret.tracks.push(trackRecord.instance?.toOptions());
      }
      console.log(ret);
      const str = JSON.stringify(ret);
      console.log(str);
      window.navigator.clipboard.writeText(str).then(() => {
        alert("Project data copied to clipboard!");
      });
    },
    importProject() {
      const loadedData = JSON.parse(prompt("enter data"));
      console.log(loadedData);
      if (!loadedData) return;
      this.clearProject();
      this.$nextTick(() => {
        try {
          this.bpm = loadedData.bpm;
          this.setBpm();
          this.transport.swing = loadedData.swing ?? 0;
          loadedData.tracks.map((trackOptions) => {
            this.projectData.push({ initOptions: trackOptions });
            this.uidCounter = Math.max(this.uidCounter, trackOptions.uid + 1);
          });
        } catch (e) {
          console.log("Error when importing project: ", e);
          this.clearProject();
        }
      });
    },
    setClipboard(obj) {
      this.clipboard = obj;
    },
    getClipboard() {
      return this.clipboard;
    },
  },
  created() {
    this.setBpm();
    Tone.Destination.connect(this.recorder);
  },
});
</script>

<style scoped>
.daw-main {
  margin: auto;
  width: calc(100vw - 6em);
  padding: 1em 1em;
}
.transport-control {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.global-control {
  position: sticky;
  display: flex;
  top: 0;
  width: 100%;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.95);
  box-shadow: 0 0 32px 16px rgba(0, 0, 0, 0.95);
  z-index: 998;
  margin-bottom: 32px;
}

.project-control {
  display: flex;
}

.project-control * {
  padding: 4px;
  margin: 8px;
}

@media (max-width: 960px) {
  .global-control {
    flex-direction: column;
  }
  .transport-control {
    justify-content: center;
  }

  .project-control {
    justify-content: center;
  }
}

.play-button {
  width: 48px;
  height: 48px;
  font-size: 1.1em;
  margin-right: 1em;
}
.play-button img {
  width: 100%;
  height: 100%;
}
.bpm-setting {
  font-size: 1.5em;
  color: #0ff;
}
.bpm-input {
  text-align: right;
  font-family: inherit;
  font-size: 1.5em;
  margin-right: 0.2em;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: inherit;
  width: 2em;
}
.bpm-input:focus {
  outline: none;
}

.swing-setting {
  margin: 0 1em;
}

.add-new-track-button,
.add-new-track-button:active {
  height: 60px;
  border-radius: 8px;
  border-style: dashed;
}

.track-container {
  padding-bottom: 250px;
}
</style>
