<template>
  <div class="meter-vis-main" @click="log">
    <div class="meter-vis-bar">
      <div class="meter-vis-bar-fill" :style="fillStyle" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Meter } from "tone";
import { clamp } from "../utils/SHLMath";

export default Vue.extend({
  name: "MeterVisualizer",
  props: {
    meter: Object as Meter,
  },
  data() {
    return {
      fillRatio: 0.5,
    };
  },
  mounted() {
    this.renderLoop();
  },
  methods: {
    renderLoop() {
      if (!this.meter) return;
      window.requestAnimationFrame(this.renderLoop.bind(this));
      const meterVal = this.meter.getValue();
      this.fillRatio = clamp(meterVal, 0, 1);
    },
    log() {
      console.log(this.meter.getValue());
    },
  },
  computed: {
    fillStyle() {
      return {
        transform: `scaleY(${this.fillRatio})`,
      };
    },
  },
});
</script>

<style scoped>
.meter-vis-main {
  height: 100%;
  padding-right: 5px;
  display: flex;
  justify-content: stretch;
}

.meter-vis-bar {
  width: 4px;
  border-radius: 2px;
  margin: 2px 0;
  background-color: rgba(255, 140, 0, 0.25);
}

.meter-vis-bar-fill {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-color: rgb(255, 140, 0);
  transform-origin: bottom;
}
</style>
