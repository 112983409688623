<template>
  <div
    class="toggle-main"
    :class="{ 'toggle-main-active': active }"
    @click="handleLeftClick"
  >
    <span>{{ truncateString(propName) }}</span>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "Toggle",
  props: {
    propName: {
      type: String,
      default: "togg",
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleLeftClick() {
      this.$emit("toggle", this.propName);
    },
    truncateString(str) {
      if (str.length <= 5) return str;
      const lastChar = str.substr(-1, 1);
      if (lastChar >= "0" && lastChar <= "9") {
        return str.slice(0, 4) + lastChar;
      }
      return str.slice(0, 5);
    },
  },
});
</script>

<style scoped>
.toggle-main {
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 32px;
  border: #0ff 2px solid;
  box-sizing: border-box;
  color: #0ff;
  transition: all 0.25s;
}
.toggle-main:hover {
  background-color: #044;
}
.toggle-main-active {
  transform: translateY(2px);
  background-color: #0ff;
  color: #000;
}
.toggle-main-active:hover {
  background-color: #4ff;
  color: #000;
}
.toggle-main span {
  user-select: none;
  width: 100%;
  position: absolute;
  bottom: calc(50% - 0.5em);
  text-align: center;
}
</style>
