<template>
  <div v-on="$listeners" class="styled-button-main">
    <slot />
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "StyledButton",
  data() {
    return {};
  },
});
</script>

<style scoped>
.styled-button-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  user-select: none;
  border: #0ff 2px solid;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0);
  color: #0ff;
  transition: all 0.1s;
  font-family: inherit;
  text-align: center;
}
.styled-button-main:hover {
  background-color: #044;
}
.styled-button-main:active {
  border: #0ff 2px solid;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0);
  transform: translateY(2px);
}
.styled-button-main:focus {
  color: #8ff;
}
</style>
