/* Global css goes here! */
html {
  background: #000;
  color: #fff;
  font-family: "Consolas", monospace, sans-serif;
}

body {
  margin: 0 auto 0 calc(100vw - 100%);
}

@keyframes FadeInLeft {
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes FadeInDown {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}

