<template>
  <div id="cc-main">
    <div class="slider">
      <span class="slider-tag">Gain</span>
      <input
        v-model="channelParams.volume"
        type="range"
        min="-60"
        max="0"
        step="0.1"
        @input="emitData"
      />
      <span class="slider-value"
        >{{ Number(channelParams.volume).toFixed(2) }}db</span
      >
    </div>
    <div class="slider">
      <span class="slider-tag">Pan</span>
      <input
        v-model="channelParams.pan"
        type="range"
        min="-1"
        max="1"
        step="0.1"
        @input="emitData"
      />
      <span class="slider-value">{{
        Number(channelParams.pan).toFixed(1)
      }}</span>
    </div>
    <div class="button-area">
      <div
        class="button button-solo"
        @click="toggleSolo"
        :class="{ 'button-active': channelParams.solo }"
      />
      <div
        class="button button-mute"
        @click="toggleMute"
        :class="{ 'button-active': channelParams.mute }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { Channel } from "tone";

export default Vue.extend({
  name: "ChannelControl",
  props: {
    channel: Object as PropType<Channel>,
  },
  watch: {
    channel() {
      this.channelParams = this.channel;
    },
  },
  data() {
    return {
      channelParams: { ...this.channel },
    };
  },
  methods: {
    emitData() {
      this.$emit("update:channel", this.channelParams);
    },
    toggleSolo() {
      this.channelParams.solo = !this.channelParams.solo;
      this.emitData();
    },
    toggleMute() {
      this.channelParams.mute = !this.channelParams.mute;
      this.emitData();
    },
  },
});
</script>

<style scoped>
#cc-main {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-right: 0.5em;
}
.slider {
  position: relative;
  display: inline-block;
  margin: 0 0.5em;
}
.slider input[type="range"] {
  width: 120px;
}
.slider-tag {
  position: absolute;
  left: 0;
  top: -1em;
}
.slider-value {
  position: absolute;
  right: 0;
  top: -1em;
}
.button-area {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
}
.button {
  width: 24px;
  height: 24px;
  border: 1px #fff solid;
  border-radius: 4px;
  text-align: center;
}
.button-active {
  background: #fff;
  color: #000;
}
.button-solo::after {
  content: "S";
}
.button-mute::after {
  content: "M";
}
</style>
