<template>
  <div class="menu-main">
    <div class="menu-backdrop" @click="$emit('dismiss-menu')">
      <div class="menu-item-container" :style="itemContainerStyle" ref="menuEl">
        <div
          class="menu-item"
          v-for="item of items"
          :key="item"
          @click="$emit('select-menu-item', item)"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "Menu",
  props: {
    items: Array,
    anchor: null as Element | MouseEvent,
  },
  data() {
    return {
      menuDimensions: null,
    };
  },
  mounted() {
    this.menuDimensions = this.$refs.menuEl.getBoundingClientRect();
  },
  computed: {
    itemContainerStyle() {
      if (!this.anchor) return {};
      const rect = this.anchor.getBoundingClientRect?.() ?? {
        left: this.anchor.x,
        top: this.anchor.y,
      };
      const selfRect = this.menuDimensions ?? {
        width: 100,
        height: 100,
      };
      return {
        left: Math.min(window.innerWidth - selfRect.width, rect.left) + "px",
        bottom: window.innerHeight - Math.max(rect.top, selfRect.height) + "px",
      };
    },
  },
});
</script>

<style scoped>
.menu-main {
  user-select: none;
  color: #0ff;
}

.menu-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}

.menu-item-container {
  z-index: 1000;
  width: fit-content;
  position: fixed;
  animation: FadeInDown 0.25s;
}

.menu-item {
  border: #0ff 1px solid;
  padding: 0.2em 0.5em;
  transition: all 0.15s;
}

.menu-item:hover {
  color: #000;
  background-color: #0dd;
}
</style>
