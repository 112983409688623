<template>
  <div class="mono-sampler-main">
    <div class="sample-title">Current Sample: {{ device.sampleName }}</div>
    <div class="sample-display" @click="onSampleDisplayClicked">
      <canvas
        id="sample-display-canvas"
        ref="displayCanvas"
        width="480"
        height="100"
      />
      <div v-if="!isSampleLoaded" class="sample-loading-placeholder">
        Loading...
      </div>
    </div>
    <StyledButton class="select-sample-button" @click="openSampleMenu"
      >Select sample</StyledButton
    >
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { SHLMonoSampler } from "../SHLTone/SHLMonoSampler";
import { SampleList } from "../configs/SampleList";
import StyledButton from "./widgets/StyledButton.vue";
import { map } from "../utils/SHLMath";

export default Vue.extend({
  name: "SHLMonoSamplerCustomControl",
  components: { StyledButton },
  props: {
    device: SHLMonoSampler,
  },
  inject: ["callMenu"],
  data() {
    return {
      isSampleLoaded: this.device.sampleBuffer.loaded,
    };
  },
  mounted() {
    this.drawCanvas();
  },
  methods: {
    openSampleMenu(event: MouseEvent) {
      this.callMenu(
        Object.keys(SampleList),
        event.target,
        this.onSampleMenuItemSelected.bind(this)
      );
    },
    onSampleMenuItemSelected(item: string) {
      this.device.add("A1", SampleList[item], this.drawCanvas.bind(this), item);
      this.isSampleLoaded = false;
    },
    onSampleDisplayClicked() {
      this.device.triggerAttackRelease("A1", "16n");
    },
    drawCanvas() {
      if (!this.device.sampleBuffer.loaded) {
        setTimeout(() => this.drawCanvas(), 200);
        return;
      }
      this.isSampleLoaded = true;
      const context = this.$refs.displayCanvas.getContext("2d");
      context.clearRect(0, 0, 480, 100);
      const bufferData = this.device.sampleBuffer.toArray(0) as Float32Array;
      context.beginPath();
      context.moveTo(0, 50);
      const indexIncrement = Math.floor(bufferData.length / 480);
      for (let i = 1; i <= 480; i++) {
        context.lineTo(
          i,
          map(bufferData[(i - 1) * indexIncrement], -1, 1, 0, 100)
        );
      }
      context.strokeStyle = "#f80";
      context.lineWidth = 2;
      context.stroke();
    },
  },
  computed: {},
});
</script>

<style scoped>
.mono-sampler-main {
  display: flex;
  flex-direction: column;
  padding: 4px;
}

.sample-display {
  position: relative;
  cursor: pointer;
  border: #f80 1px solid;
  width: 480px;
  height: 100px;
  margin: 8px 0;
}

#sample-display-canvas {
  width: 480px;
  height: 100px;
}

.select-sample-button {
  padding: 8px;
}

.sample-title {
  padding-left: 0.5em;
}

.sample-loading-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000d;
  color: #f80;
}
</style>
