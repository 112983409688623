<template>
  <div class="detail-panel-main" v-if="track != null">
    <div class="detail-panel-device-container">
      <DeviceControl :device="track.instrument" :key="track.uid" />
      <DeviceControl
        v-for="(effect, index) in track.effects"
        :device="effect"
        :device-index="index"
        :total-device-count="track.effects.length"
        @delete="handleDeleteEffect"
        @move="handleMoveEffect"
        :key="track.uid + '.' + effect + '.' + effect.uniqueId"
      />
      <StyledButton class="track-add-effect-button" @click="openMenu"
        >Add Effect</StyledButton
      >
      <div class="detail-panel-right-padding"></div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { EffectList } from "../configs/DeviceList";
import DeviceControl from "./DeviceControl.vue";
import { SHLTrack } from "../SHLTone/SHLTrack";
import StyledButton from "./widgets/StyledButton.vue";

const KEY_NOTE_MAPPING = {
  a: "C",
  w: "C#",
  s: "D",
  e: "D#",
  d: "E",
  f: "F",
  t: "F#",
  g: "G",
  y: "G#",
  h: "A",
  u: "A#",
  j: "B",
};

export default Vue.extend({
  name: "TrackDetailPanel",
  components: {
    StyledButton,
    DeviceControl,
  },
  props: {
    track: Object as SHLTrack | null,
  },
  inject: ["callMenu"],
  data() {
    return {
      keyEvent: this.onKeyEvent.bind(this),
      previewOctave: 3,
    };
  },
  mounted() {
    window.addEventListener("keydown", this.keyEvent);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyEvent);
  },
  methods: {
    handleDeleteEffect(index) {
      this.track.removeEffect(index);
    },
    handleMoveEffect({ from, to }) {
      this.track.moveEffect(from, to);
    },
    openMenu(event: MouseEvent) {
      this.callMenu(Object.keys(EffectList), event.target, (item) =>
        this.addEffect(item)
      );
    },
    addEffect(item) {
      const newEffect = new EffectList[item]();
      this.track.addEffect(newEffect);
    },
    onKeyEvent(event: KeyboardEvent) {
      if (event.defaultPrevented) return;
      if (event.key === "z") {
        this.previewOctave = Math.max(this.previewOctave - 1, -4);
      } else if (event.key === "x") {
        this.previewOctave = Math.min(this.previewOctave + 1, 10);
      } else if (
        Object.prototype.hasOwnProperty.call(KEY_NOTE_MAPPING, event.key)
      ) {
        const fullNote = KEY_NOTE_MAPPING[event.key] + this.previewOctave;
        this.$emit("key-pressed", fullNote);
        this.track?.instrument.triggerAttackRelease(fullNote, "16n");
      }
    },
  },
});
</script>

<style scoped>
.detail-panel-main {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.95);
  box-shadow: 0 0 32px 16px rgba(0, 0, 0, 0.95);
  animation: FadeInDown 0.25s;
  width: 100%;
  max-width: calc(100vw - 6em);
  bottom: 0;
  left: 0;
  height: 240px;
  margin: 3em auto 1em 3em;
  border: #0ff 2px solid;
  border-radius: 8px;
  box-sizing: border-box;
  overflow-x: auto;
  overflow-y: hidden;
}

.detail-panel-device-container {
  display: flex;
  height: 100%;
}

.track-add-effect-button,
.track-add-effect-button:active {
  padding: 1em;
  margin: 4px;
  flex-shrink: 0;
  border-style: dashed;
  border-radius: 8px;
}

.detail-panel-right-padding {
  height: 100%;
  min-width: 0.2em;
}
</style>
