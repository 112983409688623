<template>
  <div class="params-control-wrapper">
    <div class="params-control params-control-self">
      <div class="params-title">{{ title }}</div>
      <div class="self-container">
        <div class="self-custom">
          <slot />
        </div>
        <div class="self-default">
          <div class="upper">
            <component
              v-for="propName in upperItems"
              :is="getWidget(propName)"
              :key="path + '.' + propName"
              :title="path + '.' + propName"
              :prop-name="propName"
              :value="options[propName]"
              :active="propName === childPropName"
              :param-attributes="getParamAttributes(path + '.' + propName)"
              @input="handleKnob"
              @toggle="handleToggle"
            />
          </div>
          <div class="lower">
            <component
              v-for="propName in lowerItems"
              :is="getWidget(propName)"
              :key="path + '.' + propName"
              :title="path + '.' + propName"
              :prop-name="propName"
              :value="options[propName]"
              :active="propName === childPropName"
              :param-attributes="getParamAttributes(path + '.' + propName)"
              @input="handleKnob"
              @toggle="handleToggle"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="params-control params-control-child"
      v-if="childPropName !== undefined"
    >
      <ParamsControl
        :options="options[childPropName]"
        :root="childPropName"
        :path="path + '.' + childPropName"
        :key="path + '.' + childPropName"
        :title="childPropName"
        @input="handleKnob"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Knob from "./widgets/Knob.vue";
import Toggle from "./widgets/Toggle.vue";
import {
  getAttribute,
  hasAttribute,
  isBlacklisted,
} from "../configs/ParamsAttributes";

export default Vue.extend({
  name: "ParamsControl",
  components: {
    Toggle,
    Knob,
  },
  props: {
    title: String,
    root: {
      type: String,
      default: "",
    },
    path: {
      type: String,
      default: "",
    },
    options: Object,
  },
  data() {
    return {
      childPropName: undefined,
    };
  },
  computed: {
    propertyList() {
      const ret = [];
      for (let p in this.options) {
        if (
          !isBlacklisted(p) &&
          typeof this.options[p] != "function" &&
          typeof this.options[p] != "boolean" &&
          (typeof this.options[p] != "string" ||
            hasAttribute(this.path + "." + p))
        ) {
          ret.push(p);
        }
      }
      return ret;
    },
    upperItems() {
      return this.propertyList.length <= 1
        ? this.propertyList
        : this.propertyList.slice(0, (this.propertyList.length + 1) / 2);
    },
    lowerItems() {
      return this.propertyList.length <= 1
        ? []
        : this.propertyList.slice(
            (this.propertyList.length + 1) / 2,
            this.propertyList.length
          );
    },
  },
  methods: {
    handleKnob(e) {
      this.$emit(
        "input",
        this.root == ""
          ? e
          : {
              [this.root]: e,
            }
      );
    },
    handleToggle(e) {
      if (this.childPropName === e) {
        this.childPropName = undefined;
      } else {
        this.childPropName = e;
      }
    },
    getWidget(propName) {
      return typeof this.options[propName] == "object" ? Toggle : Knob;
    },
    getParamAttributes(fullPropName) {
      return getAttribute(fullPropName);
    },
  },
});
</script>

<style scoped>
.upper,
.lower {
  display: flex;
  flex: 1;
  align-items: center;
}
.params-control-wrapper {
  display: flex;
  height: 100%;
  animation: FadeInLeft 0.25s;
}
.params-control-child {
  margin-left: 1em;
}
.params-control {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.params-title {
  margin-bottom: 0.5em;
  padding: 0 0.25em;
  user-select: none;
}
.self-container {
  display: flex;
  flex-grow: 1;
}
.self-custom {
  display: flex;
  border: #0ff 1px solid;
  border-radius: 8px;
  margin-right: 1em;
}
.self-custom:empty {
  display: none;
}
.self-default {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 4px;
  border: #0ff 1px solid;
  border-radius: 8px;
  justify-content: space-evenly;
}
.self-default * {
  margin: 4px;
}
</style>
