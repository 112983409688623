<template>
  <div
    class="seq-cell-main"
    :class="{ 'seq-cell-filled': value !== '', 'seq-cell-focused': isFocused }"
    @click="handleLeftClick"
    @contextmenu="handleRightClick"
  >
    <span class="seq-cell-note" v-if="!mono">
      {{ value }}
    </span>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Note } from "tone/build/esm/core/type/NoteUnits";
import { PianoContext } from "../utils/SHLTypes";

export default Vue.extend({
  name: "SequencerCell",
  props: {
    mono: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Note,
      default: "F#11",
    },
    instrument: Object,
  },
  data() {
    return {
      isFocused: false,
    };
  },
  methods: {
    handleLeftClick(event: MouseEvent) {
      event.preventDefault();
      if (event.button == 2) {
        // Right click: Clear
        this.$emit("input", "");
        this.$emit("update");
        return;
      }

      if (this.mono) {
        this.$emit("input", "C1"); // dummy value
        this.$emit("update");
      } else if (!this.isFocused) {
        this.isFocused = true;
        this.$emit("evoke-piano", {
          activatingMouseEvent: event,
          currentNote: this.value,
          instrument: this.instrument,
          onSelected: (note: Note) => {
            this.$emit("input", note);
            this.$emit("update");
          },
          onCancel: () => {
            this.isFocused = false;
          },
        } as PianoContext);
      }
    },
    handleRightClick(event: MouseEvent) {
      event.preventDefault();
      this.$emit("input", "");
      this.$emit("update");
    },
  },
});
</script>

<style scoped>
.seq-cell-main {
  display: flex;
  justify-content: center;
  min-width: 0;
  height: 48px;
  background-color: rgba(0, 0, 0, 0);
  color: #000000;
  transition: all 0.05s;
  opacity: 0.4;
  margin: 2px;
  border: 2px #0ff solid;
  border-radius: 2px;
}

.seq-cell-filled {
  background-color: #0ff;
}

.seq-cell-focused {
  opacity: 1;
  transform: scale(1.2);
  box-shadow: 0 0 4px 0 #000;
  z-index: 1;
}

.seq-cell-main:hover {
  opacity: 1;
}

.seq-cell-note {
  user-select: none;
  text-align: center;
  vertical-align: middle;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  font-size: 1.2em;
}
</style>
